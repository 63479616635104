<script setup lang="ts">
import { mdiCog } from "@mdi/js";

// composables
const { t, locale: currentLocale, locales, setLocale } = useI18n();

const locale = computed({
  get: () => currentLocale.value,
  set: (value) => setLocale(value),
});

const props = defineProps<{
  isListItem?: boolean;
}>();
</script>

<template>
  <MsSettingDialog
    v-model="locale"
    :locales="locales as LocaleObject[]"
  >
    <template
      v-if="props.isListItem"
      #activator="{ props: activatorProps }"
    >
      <MListItem
        v-bind="activatorProps"
        :title="t('app-settings')"
        :prependIcon="mdiCog"
        density="compact"
      />
    </template>
  </MsSettingDialog>
</template>
